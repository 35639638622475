import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';

declare var $:any;

@Component({
  selector: 'banner-slider-slick',
  templateUrl: './banner-slider-slick.component.html',
  styleUrls: ['./banner-slider-slick.component.scss']
})
export class BannerSliderSlickComponent implements OnInit,AfterViewInit {
  
  @Input("data")  data               = null; 
  @Input("template")  template       = null; 
  @Input("classCustom")  classCustom = null; 
  @ViewChild("banner") banner:ElementRef;
  public banners       = []; 
  public width         = null;
  public open          = true

  constructor(
    private route: Router
  ){

  }
  /**
   * 
   * Inicializa o banner
   * 
   */
  initBanner(){

    $(this.banner.nativeElement).slick({
      infinite: true,
      autoplay: true,
      dots: false,
      arrows: false
    });
    $(this.banner.nativeElement).slick('refresh');

  }
  /**
   * 
   * Retorna o link do produto
   * 
   */
  getLinkProduto(banner){

    return "/servicos/"+banner.categoria_apelido+"/"+banner.produto_apelido;

  }
  /**
   * 
   * Retorna o link do produto
   * 
   */
  getLinkCategoria(banner){

    return "/servicos/"+banner.categoria;

  }
  /**
   * 
   * To cidades
   * 
   */
  toCidades(){

    this.route.navigateByUrl("/a-serra-gaucha");   
    
  }
  /**
   * 
   * Prev
   * 
   */
  prevSlide(){

    $(this.banner.nativeElement).slick("slickPrev");

  }
  /**
   * 
   * Next
   * 
   */
  nextSlide(){

    $(this.banner.nativeElement).slick("slickNext"); 
  
  }
  /**
   * 
   * On resize
   * 
   */
   onResize(){

    this.width = window.innerWidth;

    this.open = false;

    setTimeout(() => {
      this.open = true; 
    },300); 
    setTimeout(() => {
      $(this.banner.nativeElement).slick('refresh');
    },100);
       
  }
  /**
   * 
   * Get image
   * 
   */
  getImage(i){

    let image = null;

    if(this.width <= 700){
      image = i.imagem_mobile;
    }else{
      image = i.imagem; 
    }

    return image;

  }
  /***
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit(){
    this.width = window.innerWidth;
  }
  /**
   * 
   * After View loaded
   * 
   */
  ngAfterViewInit(): void {
    this.initBanner();
  }

}
