<div [ngSwitch]="template">
    <div *ngSwitchCase="null" class="module-newsletter" [ngStyle]="{'background-image': 'url(images/newsletter/bg-2.jpg)'}">
    <div class="content">
        <div class="container">
            <div class="row">
                <div class="col-md-12">  
                    <form class="form-custom" (submit)="send()">
                        <div class="item item-header">
                            <h4 class="wow slideInUp" data-wow-delay="0.8s">{{data.titulo}}</h4>
                            <span class="wow slideInDown" data-wow-delay="0.5s">{{data.subtitulo}}</span>
                        </div>    
                        <div class="item item-input wow fadeIn" data-wow-delay="0.3s">
                            <input type="text" [(ngModel)]="dataItem.nome" name="nome" placeholder="Informe seu nome" />
                        </div>    
                        <div class="item item-input wow fadeIn" data-wow-delay="0.3s">
                            <input type="text" [(ngModel)]="dataItem.email" name="email" placeholder="Informe seu email" />    
                        </div> 
                        <div class="item item-input wow fadeIn" data-wow-delay="0.3s"> 
                            <input type="text" [(ngModel)]="dataItem.telefone" mask="(99) 99999-9999" name="telefone" placeholder="Informe seu whatsapp" />    
                        </div>    
                        <div class="item item-button wow fadeIn" data-wow-delay="0.5s">
                            <button> 
                                <span>Me cadastrar</span>
                            </button>
                        </div>    
                    </form> 
                </div>
            </div>   
        </div>
    </div>
    <div class="bg"></div>
    </div>
    <div *ngSwitchCase="'blog'" class="template-blog">
        <div class="content">
            <form class="form-custom" (submit)="send()" >
                <div class="item item-input">
                    <input type="text" [(ngModel)]="dataItem.nome" name="nome" placeholder="Informe seu Nome" />
                </div>    
                <div class="item item-input">
                    <input type="text" [(ngModel)]="dataItem.email" name="email" placeholder="Informe seu email" />    
                </div> 
                <div class="item item-input">
                    <input type="text" [(ngModel)]="dataItem.telefone" name="telefone" placeholder="Seu WhatsApp" mask="(99) 99999-9999" /> 
                </div>    
                <div class="item item-button">
                    <button class="btn-one btn-icon"> 
                        <div class="icon">
                            <i class="fa-solid fa-user"></i>
                        </div>
                        <span>Me cadastrar</span>
                    </button>
                </div>    
            </form> 
        </div>    
    </div>
</div>  
<loader *ngIf="loader"></loader>
