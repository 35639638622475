<!--<header class="header" #header (window:resize)="onResize()">
    <div class="content">
        <div class="container">
            <div class="row">
                <div class="col-md-4 col-4 col-logo">
                    <modulo posicao="logo"></modulo>
                </div>  
                <div class="col-md-8 col-8">
                    <menu-action-partial></menu-action-partial>
                </div>    
            </div>    
        </div>     
    </div>
    <div class="content-menu">
        <div class="container">
            <menu-partial [classCustom]="classCustom"></menu-partial>
        </div>
    </div>
</header>-->
<header class="header {{classCustomTwo}}" #header (window:resize)="onResize()">
    <div class="content">
        <div class="container">
            <modulo class="logo-blue" posicao="logo"></modulo>
            <modulo class="logo-white" posicao="logo" template="white"></modulo> 
            <menu-action-partial></menu-action-partial>
        </div>      
    </div>
    <div class="content-menu">
        <div class="container">
            <menu-partial [classCustom]="classCustom"></menu-partial>
        </div>
    </div>
    <menu-floating></menu-floating>
</header>  

