<div class="module-whatsapp" (click)="open()" *ngIf="template == null">
  <div class="content">
      <div class="header">
          <div class="icon-left">
              <i class="fa-solid fa-heart"></i>
          </div>    
          <span>Fale com <br>um consultor</span> 
      </div>
      <div class="icon">
          <i class="fa-brands fa-whatsapp"></i>
      </div> 
  </div> 
  <div class="content mobile">
      <div class="icon">
          <i class="fa-brands fa-whatsapp"></i>
      </div>  
  </div>   
</div>   
<div class="module-whatsapp-inline" (click)="open()" *ngIf="template == 'footer'">
    <div class="box-relacionamento">
        <div class="icon">
            <i class="fa-brands fa-whatsapp"></i>
        </div>     
        <strong>CENTRAL DE RELACIONAMENTO</strong>
        <div class="whatsapp-number">
            <strong>WHATSAPP</strong> 
            <a href="javascript::void();" target="_blank" (click)="open()"> 
                {{data.params.telefone}} 
            </a>    
        </div>    
    </div>
</div> 
<div class="module-whatsapp-inline" (click)="open()" *ngIf="template == 'page-contato'">
    <div class="card-item card-item-contact wow slideInUp" data-wow-delay="0.4s">
        <div class="card-item-header">
            <div class="icon">
                <i class="fa-brands fa-whatsapp"></i>
            </div>
            <h3>{{data.params.telefone}}</h3>     
        </div>
    </div> 
</div> 
