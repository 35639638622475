import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageErrorComponent } from 'src/app/components/error/page-error/page-error.component';
import { BreadcrumbsComponent } from 'src/app/components/breadcrumbs/breadcrumbs.component';
import { PartialCompraSeguraComponent } from 'src/app/components/partial/partial-compra-segura/partial-compra-segura.component';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import { BtnFavoritoModule } from '../btn/btn-favorito/btn-favorito.module';
import { InputCheckComponent } from 'src/app/components/input/input-check/input-check.component';
import { ToUpComponent } from 'src/app/components/partial/to-up/to-up.component';

@NgModule({
  declarations: [
    PageErrorComponent,
    BreadcrumbsComponent, 
    PartialCompraSeguraComponent,
    InputCheckComponent,
    ToUpComponent
  ],
  exports: [ 
    PageErrorComponent,
    BreadcrumbsComponent,
    PartialCompraSeguraComponent,
    InputCheckComponent,
    ToUpComponent 
  ],
  imports: [
    BtnFavoritoModule, 
    LazyLoadImageModule,
    CommonModule
  ]
})
export class PartialModule { }
