<footer>   
    <div class="content">
        <div class="container">
            <div class="row">
                <div class="col-md-3 col-item col-center">
                    <modulo class="logo" posicao="logo" template="footer"></modulo>
                </div>
                <div class="col-md-3 col-item">
                    <h3 class="title-h3">Institucional</h3>
                    <ul class="menu-list">
                        <li>
                            <a routerLink="/sobre" title="Conheça a Apolônio">
                                Conheça a Apolônio
                            </a> 
                        </li>
                        <li>
                            <a routerLink="/blog" title="Políticas de Cancelamento">
                                Blog
                            </a> 
                        </li>
                        <li>
                            <a routerLink="/politicas-de-cancelamento" title="Políticas de Cancelamento">
                                Políticas de Cancelamento 
                            </a> 
                        </li>
                        <li>
                            <a routerLink="/politicas-de-privacidade" title="Políticas de Privacidade">
                                Políticas de Privacidade
                            </a> 
                        </li>
                    </ul>
                </div>
                <div class="col-md-2 col-item">
                    <h3 class="title-h3">Ajuda</h3>
                    <ul class="menu-list">
                        <li>
                            <a routerLink="/contato" title="Contato">
                                Contato
                            </a> 
                        </li>
                        <li>
                            <a routerLink="/perguntas-frequentes" title="Central de Ajuda">
                                Perguntas Frequentes
                            </a>  
                        </li>
                    </ul>
                </div> 
                <div class="col-md-2 col-item"> 
                    <h3 class="title-h3">Minha Conta</h3>
                    <ul class="menu-list">
                        <li>
                            <a routerLink="/minha-conta/pedidos" title="Minhas Viagens">
                                Minhas Viagens
                            </a>    
                        </li>
                        <li>
                            <a routerLink="/carrinho" title="Meu Carrinho">
                                Meu Carrinho
                            </a>  
                        </li>
                        <li>  
                            <a routerLink="/minha-conta/perfil" title="Meu Perfil">
                                Meu Perfil
                            </a> 
                        </li>
                    </ul>
                </div>
                <div class="col-md-2 col-item">  
                    <modulo posicao="whatsapp" template="footer"></modulo>
                </div>
            </div>
        </div>  
    </div> 
    <modulo posicao="rede-social" template="footer"></modulo>
    <div class="bottom">
        <div class="container">
            <div class="copyright">
                <p>
                    <span>Copyright © 2022 - Todos os direitos reservados</span> 
                    <a href="https://digitalmovement.com.br/" target="_blank" title="Digital Movement - Soluções Digitais">
                        By <strong>Digital Movement</strong>
                        <img src="/images/logos/logo-dm.svg" alt="Digital Movement - Soluções Digitais" width="20px" height="20px" />
                    </a>
                </p>
            </div> 
        </div>  
    </div>    
</footer>     