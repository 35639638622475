<div class="module module-products">
      <div class="module-title" *ngIf="data.mostrar_titulo">
            <div class="container">
                <div class="content-title">
                    <div class="box">
                        <h2 class="wow slideInUp" data-wow-delay="0.5s">{{data.titulo}}</h2>
                    </div>
                    <div class="box">
                        <h3 class="wow slideInRight" data-wow-delay="0.8s">{{data.subtitulo}}</h3>
                    </div>
                </div>
            </div>    
        </div>
      <div class="module-content">
            <div class="container">
                  <div class="row">  
                        <div class="col-md-3 wow slideInUp" data-wow-delay="0.2.s" *ngFor="let p of data.produtos">
                              <produto-item [data]="p"></produto-item>
                        </div>      
                  </div>      
            </div>    
      </div>  
      <div class="center"> 
            <a (click)="_route('/servicos')" routerLink="/servicos" title="Passeios" class="btn-blue btn-icon wow fadeIn" data-wow-delay="0.9s">
                  <div class="icon"> 
                        <i class="fa-solid fa-ticket"></i> 
                  </div>
                  <span>Ver todas as experiências</span>
            </a> 
        </div>  
</div>      