<div class="btn-cart">
    <div class="item" (click)="toCart()">
        <div class="icon">
            <i class="fa-solid fa-cart-shopping"></i>  
        </div>
        <!--<div class="text">
            <span>Meu <br>Carrinho</span>
        </div>-->
    </div>     
</div>
