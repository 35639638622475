<div class="modulo modulo-rede-social">
  <div [ngSwitch]="template">
    <div *ngSwitchCase="'home'" class="template-home">
      <div class="content"> 
        <div class="container"> 
          <span class="hashtag wow fadeIn" data-wow-delay="0.1s">{{data.params.hashtag}}</span>
          <div class="box">
            <div class="center">
              <div class="list-items">
                <div class="item wow slideInLeft" data-wow-delay="0.5s">
                  <a href="{{data.params.facebook}}" target="__blank" title="Facebook">
                    <img defaultImage="/images/pixel/bg.png" lazyLoad="/images/template/icons/facebook-2.svg" width="55px" height="55px" alt="Facebook" />
                  </a> 
                </div> 
                <div class="item wow slideInRight" data-wow-delay="0.8s">  
                  <a href="{{data.params.instagram}}" target="__blank" title="Instagram">
                    <img defaultImage="/images/pixel/bg.png" lazyLoad="/images/template/icons/instagram-2.svg" width="55px" height="55px" alt="Instagram" />
                  </a>  
                </div>
              </div>
            </div>
          </div>
          <div class="box">
            <div class="center">
              <article class="text-box wow fadeIn" data-wow-delay="0.5s" [innerHtml]="data.params.texto | safeHtml"></article>
            </div>
          </div> 
          <div class="box">
            <div class="center"> 
              <a class="wow fadeIn" data-wow-delay="1s" href="{{data.params.trip_advisor}}" target="__blank" title="Trip Adivisor">
                <img defaultImage="/images/pixel/bg.png" lazyLoad="/images/template/icons/trip-advisor.svg" width="100px" height="80px" alt="Trip Adivisor - Costa Azul" />
              </a> 
            </div>  
          </div>  
        </div>
      </div>   
    </div>
    <div *ngSwitchCase="null" class="default">
      <div class="modulo-content modulo-content-footer">
        <ul>
          <li>
            <a href="{{data.params.facebook}}" target="__blank" title="Facebook">
              <i class="fab fa-facebook"></i>
            </a> 
          </li> 
          <li>
            <a href="{{data.params.instagram}}" target="__blank" title="Instagram">
              <i class="fab fa-instagram"></i>
            </a>  
          </li>
          <!--<li> 
            <a href="{{data.params.blog}}" target="__blank" title="Blog">
              <i class="fab fa-blogger"></i>
            </a>  
          </li>--> 
        </ul>  
      </div>  
    </div>
    <div *ngSwitchCase="'footer'" class="default-footer">
      <div class="content-redes-sociais">
        <div class="container">
            <div class="title">
                <div class="box">
                    <span class="linha"></span>
                </div>
                <div class="box">
                    <strong>{{data.titulo}}</strong>
                </div>
                <div class="box">
                    <span class="linha"></span>
                </div>
            </div> 
            <div class="list">
                <ul>
                    <li>
                      <a [href]="data.params.facebook" title="Facebook Apolônio Turismo" target="_blank">
                        <div class="icon">
                          <i class="fa-brands fa-facebook"></i>
                        </div>
                      </a>   
                    </li>
                    <li>
                      <a [href]="data.params.instagram" title="Intstagram Apolônio Turismo" target="_blank">
                        <div class="icon">
                          <i class="fa-brands fa-instagram"></i>
                        </div>
                      </a>   
                    </li>
                </ul>    
            </div>    
        </div>
      </div> 
    </div>
    <div *ngSwitchCase="'page-contato'" class="default-contato">
      <h3 class="modulo-title">Redes Sociais</h3>
      <div class="modulo-content modulo-content-footer">
        <ul>
          <li>
            <a href="{{data.params.facebook}}" target="__blank" title="Facebook">
              <i class="fab fa-facebook"></i>
            </a> 
          </li> 
          <li>
            <a href="{{data.params.instagram}}" target="__blank" title="Instagram">
              <i class="fab fa-instagram"></i>
            </a>  
          </li>
          <li>
            <a href="{{data.params.blog}}" target="__blank" title="Blog">
              <i class="fab fa-blogger"></i>
            </a>  
          </li> 
        </ul>  
      </div>  
    </div>
    <div *ngSwitchCase="'blog'" class="default-footer">
      <div class="content-item">
        <div class="contato-item">
            <ul class="list-rede-social">
                <li>
                    <a [href]="data.params.facebook" title="Facebook São Paulo Ecoturismo" target="_blank">
                      <div class="icon">
                        <i class="fa-brands fa-facebook"></i>
                      </div>
                    </a>
                </li>
                <li>
                    <a [href]="data.params.instagram" title="Intstagram São Paulo Ecoturismo" target="_blank">
                      <div class="icon">
                        <i class="fa-brands fa-instagram"></i>
                      </div>
                    </a>
                </li>    
            </ul> 
        </div>
      </div>  
    </div>
  </div>  
</div> 
